<template>
  <module-container
    route-name="customers"
    show-toolbar
    :title="$t('customers')"
  >
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <customer-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CustomerFilters from "@/modules/customers/components/CustomersFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { CustomerFilters },
})
export default class CustomersIndex extends Mixins(PaginateProviderMixin) {}
</script>
