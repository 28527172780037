<template>
  <sheet-filters
    v-model="obFilterData"
    @apply="applyFilters"
    @reset="resetFilters"
    :model-name="modelClassName"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('active')"
            :value="1"
            v-model="obFilterData.active"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('provider')"
            :value="1"
            v-model="obFilterData.provider"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col>
          <search-field
            v-model="obFilterData.search"
            clearable
            persistent-hint
            :hint="$t('customer.filters.hint')"
            @save="apply"
          />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import { AppModule } from "@/store/app";
import CustomersMixin from "../mixins/CustomersMixin";

@Component({
  components: { SheetFilters, SearchField },
})
export default class CustomerFilters extends Mixins(CustomersMixin) {
  obFilterData: Record<string, any> = {};

  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");

    this.applyFilters();
  }
}
</script>
